import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import {
    ResponseFollowersProfile,
    ResponseMyFollowers,
    ResponseUserProfile,
    ResponseUserProfilePublic,
    ResponseUserProfileView,
    ResponseViewBarter,
} from '@app/models/profile/response-profile.model';
import { DtoUpdateUser } from '@app/models/profile/dto-update-user.model';
import { DtoUpdateInformationSensitive } from '@app/models/profile/dto-update-information-sensitive.model';
import { DtoUpdatePassword } from '@app/models/profile/dto-update-password.model';
import { DtoFollowersProfile } from '@app/models/profile/dto-followers-profile.mode';
import { DtoReportUser } from '@app/models/profile/dto-report-user.model';
import { DtoUnLockUser } from '@app/models/profile/dto-unLock-user.model';
import { DtoLockUser } from '@app/models/profile/dto-lock-user.model';
import { DtoUserMap } from '@app/models/profile/dto-user-map.model';
import { DtoViewBarter } from '@app/models/profile/dto-view-barter.model';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private readonly API_URI = environment.PROXY
        ? '/api/'
        : environment.API_URL_V1;
    private http = inject(HttpClient);

    userProfile(): Observable<ResponseUserProfile> {
        return this.http.get<ResponseUserProfile>(
            `${this.API_URI}user/profile`
        );
    }

    userProfileView(id: string): Observable<ResponseUserProfileView> {
        return this.http.get<ResponseUserProfileView>(
            `${this.API_URI}user/profile/${id}`
        );
    }

    userProfilePublic(id: string): Observable<ResponseUserProfilePublic> {
        return this.http.get<ResponseUserProfilePublic>(
            `${this.API_URI}user/profile/public/${id}`
        );
    }

    updateUser(dto: DtoUpdateUser): Observable<void> {
        return this.http.put<void>(`${this.API_URI}user`, dto);
    }

    updateInformationSensitive(
        dto: DtoUpdateInformationSensitive
    ): Observable<void> {
        return this.http.put<void>(
            `${this.API_URI}user/update/information/sensitive`,
            dto
        );
    }

    sentLike(userId: string): Observable<void> {
        return this.http.put<void>(`${this.API_URI}user/likeProfile`, {
            userId,
        });
    }

    sentShare(_id: string): Observable<void> {
        return this.http.put<void>(`${this.API_URI}user/numberShares`, { _id });
    }

    updatePassword(dto: DtoUpdatePassword): Observable<void> {
        return this.http.put<void>(`${this.API_URI}user/update/password`, dto);
    }

    myFollowers(): Observable<ResponseMyFollowers> {
        return this.http.get<ResponseMyFollowers>(
            `${this.API_URI}user/myFollowers`
        );
    }

    sentFollower(userId: string): Observable<void> {
        return this.http.put<void>(`${this.API_URI}user/follower`, { userId });
    }

    followersProfile(
        dto: DtoFollowersProfile
    ): Observable<ResponseFollowersProfile> {
        let params = new HttpParams();
        params = params.append('userId', dto.userId);
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);

        return this.http.get<ResponseFollowersProfile>(
            `${this.API_URI}user/followers`,
            {
                params,
            }
        );
    }

    userMap(dto: DtoUserMap): Observable<any> {
        let params = new HttpParams();
        params = params.append('latitude', dto.latitude);
        params = params.append('longitude', dto.longitude);
        params = params.append('maxDistance', dto.maxDistance);

        return this.http.get<any>(`${this.API_URI}user/maps`, {
            params,
        });
    }

    blockUser(dto: DtoLockUser): Observable<void> {
        return this.http.post<void>(`${this.API_URI}user-blocked/block`, dto);
    }

    unLockUser(dto: DtoUnLockUser): Observable<void> {
        return this.http.post<void>(`${this.API_URI}user-blocked/unlock`, dto);
    }

    reportUser(dto: DtoReportUser): Observable<void> {
        return this.http.post<void>(`${this.API_URI}user/report-user`, dto);
    }

    viewBarter(dto: DtoViewBarter): Observable<ResponseViewBarter> {
        let params = new HttpParams();
        params = params.append('typeMatch', dto.typeMatch);
        params = params.append('typeBarter', dto.typeBarter);
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);

        return this.http.get<ResponseViewBarter>(`${this.API_URI}barter`, {
            params,
        });
    }

    getPostMyBox(): Observable<any> {
        return this.http.get<any>(`${this.API_URI}post-box`, {});
    }
}
